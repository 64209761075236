import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, TextField, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Draggable from 'react-draggable';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { rememberController } from '../../../controllers/rememberController';

const Lembretes = ({account}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [open, setOpen] = useState(false);
  const [novoLembrete, setNovoLembrete] = useState('');
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [selectedLembreteIndex, setSelectedLembreteIndex] = useState(null);
  const [reminders, setReminders] = useState([]);
  const [selectedLembreteId, setSelectedLembreteId] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const iduser = account.id;

  useEffect(() => {
    loadReminders();
  }, []);

  const loadReminders = async () => {
    const result = await rememberController.getAll(iduser);
    if (result.sucess) {
      setReminders(result.data);
    }
  };

  const handleClickOpen = () => {
    setSelectedLembreteIndex(null);
    setSelectedLembreteId(null);
    setNovoLembrete('');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const result = await rememberController.create(iduser, { 
      texto: novoLembrete, 
      cor: '#ffffff' 
    });
    if (result.sucess) {
      await loadReminders();
      setNovoLembrete('');
      setOpen(false);
    }
  };

  const handleColorChange = async (color) => {
    const lembrete = reminders[selectedLembreteIndex];
    const result = await rememberController.update(iduser, lembrete.id, {
      ...lembrete,
      cor: color.hex
    });
    if (result.sucess) {
      await loadReminders();
    }
  };

  const handleColorPickerOpen = (index) => {
    setSelectedLembreteIndex(index);
    setColorPickerOpen(true);
  };

  const handleColorPickerClose = () => {
    setColorPickerOpen(false);
  };

  const handleDelete = async (index) => {
    const lembrete = reminders[index];
    const result = await rememberController.delete(iduser, lembrete.id);
    if (result.sucess) {
      await loadReminders();
    }
  };

  const handleEdit = (index) => {
    const lembrete = reminders[index];
    setNovoLembrete(lembrete.texto);
    setSelectedLembreteIndex(index);
    setSelectedLembreteId(lembrete.id);
    setOpen(true);
  };

  const handleUpdate = async () => {
    const lembrete = reminders[selectedLembreteIndex];
    const result = await rememberController.update(iduser, selectedLembreteId, {
      ...lembrete,
      texto: novoLembrete
    });
    if (result.sucess) {
      await loadReminders();
      setNovoLembrete('');
      setOpen(false);
    }
  };

  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    const lembrete = reminders[deleteIndex];
    const result = await rememberController.delete(iduser, lembrete.id);
    if (result.sucess) {
      await loadReminders();
    }
    setDeleteConfirmOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setDeleteIndex(null);
  };

  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%',
      padding: isMobile ? 1 : 2,
      position: 'relative' // Added position relative
    }}>
      <Fab 
        color="primary" 
        aria-label="add" 
        onClick={handleClickOpen} 
        sx={{ 
          position: 'fixed',
          left: '50%', // Center horizontally
          bottom: isMobile ? 16 : 32,
          transform: 'translateX(-50%)', // Adjust for exact center
          zIndex: 1000 // Ensure button stays on top
        }}
      >
        <AddIcon />
      </Fab>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedLembreteIndex !== null ? 'Editar Lembrete' : 'Adicionar Lembrete'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Lembrete"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={novoLembrete}
            onChange={(e) => setNovoLembrete(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={selectedLembreteIndex !== null ? handleUpdate : handleSave} color="primary">
            {selectedLembreteIndex !== null ? 'Atualizar' : 'Salvar'}
          </Button>
        </DialogActions>
      </Dialog>

      <Box 
        display="flex" 
        flexWrap="wrap" 
        sx={{
          gap: isMobile ? 1 : 2,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          padding: isMobile ? 1 : 2
        }}
      >
        {reminders.map((lembrete, index) => (
          // <Draggable key={index}>
            <Box key={index}
              sx={{
                width: isMobile ? '100%' : 280,
                height: isMobile ? 120 : 180,
                padding: isMobile ? 1.5 : 2,
                backgroundColor: lembrete.cor,
                borderRadius: 2,
                boxShadow: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: isMobile ? '4px 0' : 1,
                '&:hover': {
                  boxShadow: 6,
                  transform: 'scale(1.02)',
                  transition: 'all 0.2s ease-in-out'
                }
              }}
            >
              <Typography 
                variant={isMobile ? "body2" : "body1"} 
                sx={{
                  wordWrap: 'break-word',
                  maxHeight: '100%',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: isMobile ? 3 : 4,
                  WebkitBoxOrient: 'vertical',
                  padding: '0 24px'
                }}
              >
                {lembrete.texto}
              </Typography>
              
              <Box 
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  display: 'flex',
                  gap: '2px',
                }}
              >
                <IconButton
                  size={isMobile ? "small" : "medium"}
                  aria-label="edit"
                  onClick={() => handleEdit(index)}
                >
                  <EditIcon fontSize={isMobile ? "small" : "medium"} />
                </IconButton>
                <IconButton
                  size={isMobile ? "small" : "medium"}
                  aria-label="change color"
                  onClick={() => handleColorPickerOpen(index)}
                >
                  <ColorLensIcon fontSize={isMobile ? "small" : "medium"} />
                </IconButton>
                <IconButton
                  size={isMobile ? "small" : "medium"}
                  aria-label="delete"
                  onClick={() => handleDeleteClick(index)}
                >
                  <DeleteIcon fontSize={isMobile ? "small" : "medium"} />
                </IconButton>
              </Box>
            </Box>
          // </Draggable>
        ))}
      </Box>

      <Dialog 
        open={colorPickerOpen} 
        onClose={handleColorPickerClose}
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 'auto',
            height: isMobile ? '100%' : 'auto',
            maxWidth: '300px',
            maxHeight: '450px',
            margin: isMobile ? 0 : 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& .sketch-picker': {
              width: '100% !important',
              boxSizing: 'border-box',
              padding: '10px',
            },
          },
        }}
      >
        <DialogTitle 
          sx={{ 
            width: '100%',
            textAlign: 'center',
            padding: isMobile ? 2 : 3 
          }}
        >
          Escolha a cor do lembrete
        </DialogTitle>
        <DialogContent 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: isMobile ? '16px !important' : '24px !important',
            width: '100%',
          }}
        >
          <SketchPicker
            color={reminders[selectedLembreteIndex]?.cor}
            onChangeComplete={handleColorChange}
            styles={{
              default: {
                picker: {
                  width: '100% !important',
                }
              }
            }}
          />
        </DialogContent>
        <DialogActions 
          sx={{ 
            padding: isMobile ? 2 : 3,
            justifyContent: 'center' 
          }}
        >
          <Button 
            onClick={handleColorPickerClose} 
            color="primary"
            variant="contained"
            sx={{ minWidth: 100 }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza que deseja excluir este lembrete?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Lembretes;