// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth, onAuthStateChanged } from 'firebase/auth';


// Initialize Firebase
export const app = initializeApp(require(process.env.REACT_APP_FIREBASE_CONFIG));

export const auth = getAuth(app)

auth.languageCode = 'pt-BR'
auth.useDeviceLanguage()

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // Usuário está autenticado
        resolve(user);
      } else {
        // Nenhum usuário está autenticado
        resolve(null);
      }
    }, (error) => {
      // Tratar erros
      reject(error);
    });
  });
};


export const storage = getStorage(app);
export const db = getFirestore(app)





