
import { openai } from './openai'

export async function generateAIText(texto, max_tokens) {

  const prompt = texto

  const temperature = 0.7

  if (max_tokens > 750) { max_tokens = 750 }

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Iniciou AI')
  const response = await openai.chat.completions.create({
    model: 'gpt-4o-mini',
    temperature,
    messages: [
      { role: 'user', content: prompt }
    ],
    max_tokens: max_tokens
  })
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Encerrou AI', response)
  return response
}

